// AdditionModal.js
import React from 'react';
import './AdditionModal.css'; // Import CSS file

const GenericModal = ({
    isOpen,
    Component, // Dynamic component to render
    componentProps = {} // Props to pass to the dynamic component
}) => {
    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={componentProps.onClose}>X</button>
                        {Component && <Component {...componentProps} />}
                    </div>
                </div>
            )}
        </>
    );
};

export default GenericModal;
