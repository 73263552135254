import React, { useState } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Assuming you're using FontAwesome
import { IconData, IconColor } from '../CardActions/ActionMapping'; // Import your icon mappings
import './ActionGrid.css';
import AddFinEntry from '../Finance/AddFinEntry';
import GenericModal from '../../modals/GenericModal';

const ActionGrid = ({ data, columns, handleDecisionSubmitClick }) => {
    const [filteredData, setFilteredData] = useState(data);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [placeholder, setPlaceholder] = useState(null);

    // Handle sorting logic
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        const sorted = [...filteredData].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredData(sorted);
    };

    const handleCloseFinModal = () => {
        //setIsFinModalOpen(false); // Close the modal
        setPlaceholder(<></>);
    };

    // Handle button click event
    const handleButtonClick = (item, row) => {
        console.log('Button clicked:', item, 'Row data:', row);

        setPlaceholder(
            <GenericModal
                isOpen={true}
                Component={AddFinEntry}
                componentProps={{


                    // TODO: get these commentted attribute their right values. When saving the item, instead of replacing the entire obj, just change the attribute for which value has changed, so only send delta from the front end.



                    //attributeId: row.AttributeId,
                    onSubmitClick: handleDecisionSubmitClick,
                    parent: '{ Text: "New Subscriptions", Description: "Add Subscription Service" }',
                    optionsFromParent: [row.subCat],
                    existingCard: row,
                    onClose: handleCloseFinModal,
                    callingOption: item.Description
                }} />);
    };

    // Function to format data based on type
    const formatValue = (item, column) => {
        const value = item[column.value_from]; // Get the amount value from the data object
        switch (column.type) {
            case 'currency':
                // Convert value to number and format it to two decimal places
                const formattedNumber = parseFloat(value).toFixed(2);
                const currencyField = column.formatter.value_from; // Get the currency field name from formatter
                const currencySymbol = item[currencyField]; // Retrieve the currency symbol from the data object
                return `${currencySymbol} ${formattedNumber}`; // Concatenate formatted number with currency symbol
            case 'date':
                const date = new Date(value);
                const options = { day: '2-digit', month: 'short', year: 'numeric' };
                return date.toLocaleDateString('en-US', options).replace(/(\d{2}) (\w{3}) (\d{4})/, '$1 $2 $3'); // Format to DD MMM YYYY
            case 'icon':
                return <img src={value} alt="icon" className="eic-sub-grid-image-box" />; // Render icon with specified CSS class
            default:
                return value; // Default to text
        }
    };


    return (
        <div className="ag-expense-income-card">
            {/*<h2>Past Actions Factors in Recommendation</h2>*/}
            <div className="ag-grid-container">
                <Grid container spacing={0} className="ag-grid-header">
                    {columns.map((column, index) => (
                        <Grid key={index} item xs className="ag-bordered-grid">
                            <div className="ag-header-cell" onClick={() => handleSort(column.value_from)}>
                                <span className="ag-header-text">{column.display_name}</span>
                            </div>
                        </Grid>
                    ))}
                </Grid>

                {filteredData.map((item, rowIndex) => (
                    <Grid
                        container
                        spacing={0}
                        className={`ag-grid-row ${rowIndex % 2 === 0 ? 'ag-alternate-row' : ''}`}
                        key={rowIndex}
                    >
                        {columns.map((column, colIndex) => (
                            <Grid key={colIndex} item xs className="ag-bordered-grid ag-data-cell">
                                {column.type === 'buttons' ? (
                                    <Grid container spacing={1}>
                                        {column.render && column.render.length > 0 && (
                                            column.render.map((button) => (
                                                <Grid item xs={5} key={button.id}>
                                                    <Tooltip title={button.Description} aria-label={button.Description} zIndex={2000}>
                                                        <span
                                                            className={`icon-small-circle ${IconColor[button.text]}`}
                                                            onClick={() => handleButtonClick(button, item)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={IconData[button.text]}
                                                                style={{ color: 'white' }}
                                                                size="sm" // Adjust size as needed
                                                                aria-label={button.Description}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>
                                ) : (
                                    <span>{formatValue(item, column)}</span>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </div>
            {placeholder}
        </div>
    );
};

export default ActionGrid;
