import React from 'react';
import './ExpenseIncomeCard.css'; // Import CSS file
import { Typography, Grid } from '@material-ui/core';
import ActionGrid from '../GenericComponents/ActionGrid';

const ExpenseIncomeCard = ({ data, handleDecisionSubmitClick }) => {
    // Calculate the sum of amount where CallingOption is 'Income' and expenses
    const sumOfIncome = data !== undefined ? data
        .filter(item => item.CallingOption === 'Income') ?
        data
            .filter(item => item.CallingOption === 'Income')
            .reduce((acc, item) => acc + Number(item.amount), 0)
        :
        'Nothing added'
        :
        'Nothing added';

    const sumOfExpense = data !== undefined ? data
        .filter(item => (item.CallingOption === 'Expense' || item.CallingOption === 'New Subscriptions')) ?
        data
            .filter((item => item.CallingOption === 'Expense' || item.CallingOption === 'New Subscriptions'))
            .reduce((acc, item) => acc + Number(item.amount), 0)
        :
        'Nothing added'
        :
        'Nothing added';

    const columnKeys = [
        {
            value_from: 'subCat',
            display_name: 'Category',
            type: 'text'
        },
        {
            value_from: 'title',
            display_name: 'Title',
            type: 'text'
        },
        {
            value_from: 'amount',
            display_name: 'Amount',
            type: 'currency', // Specify type as currency
            formatter: {
                value_from: 'currency',
            }
        },
        {
            value_from: 'start',
            display_name: 'Date',
            type: 'date', // Specify type as date
            formatter: {
                format: 'DD/MM/YYYY' // Example format, adjust as needed
            }
        },
        {
            value_from: "actions",
            display_name: "Actions",
            type: "buttons",
            render: [
                { id: 1, text: "faEdit", Description: "Edit" },
                { id: 2, text: "faTrashAlt", Description: "Delete" }
            ]
        }
    ];


    return (
        <div className="expense-income-card">
            <div className="eic-card-body">
                <div className="eic-column-fullwidth">
                    <Grid container spacing={3} className="gridContainer">
                        <Grid item xs={6} className="bordered-grid centered-text">
                            <Typography variant="h5" style={{ color: sumOfIncome < sumOfExpense ? 'red' : 'black' }}>
                                Liabilities: {(sumOfExpense / 1).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="bordered-grid centered-text">
                            <Typography variant="h5" style={{ color: sumOfIncome > sumOfExpense ? 'green' : 'black' }}>
                                Assets: {(sumOfIncome / 1).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <ActionGrid
                data={data}
                onUpdateDecisionClick={null}
                onClose={null}
                columns={columnKeys}
                handleDecisionSubmitClick={handleDecisionSubmitClick}
            >
            </ActionGrid>
            <div className="eic-card-body">
                <div className="eic-column-fullwidth">
                    <Grid container spacing={3} className="gridContainer">
                        <Grid item xs={5}>
                            <Typography variant="h5" className="bordered-grid centered-text">
                                Title
                            </Typography>
                        </Grid>

                        <Grid item xs={5}>
                            <Typography variant="h5" className="bordered-grid centered-text">
                                Amount
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>

                        </Grid>
                    </Grid>
                    {data && data.map((item, index) => (
                        <Grid container spacing={3} className="gridContainer">
                            <Grid item xs={5} className="bordered-grid">
                                <Typography variant="h6">{item.title}</Typography>
                            </Grid>

                            <Grid item xs={5} className="bordered-grid">
                                <Typography variant="h6" >
                                    {(item.amount / 1).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className="bordered-grid">
                                <Typography variant="h6">{(item.CallingOption === 'Expense' ? 'Cr' : 'Db')}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </div>
            </div>
        </div >
    );
};

export default ExpenseIncomeCard;
