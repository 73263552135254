const MapSearch = require("./MapSearchAction");
const SaveEvents = require("./SaveEventsAction");
const { faPlus, faThumbsUp, faThumbsDown, faMapLocation, faCalendarPlus, faRefresh, faTrashAlt, faEdit } = require('@fortawesome/free-solid-svg-icons');

const IconActions = {
    'MapSearch': new MapSearch(),
    'SaveEvents': new SaveEvents(),
}

const IconMappings = {
    'faBookmark': faPlus,
    'faThumbsUp': faThumbsUp,
    'faThumbsDown': faThumbsDown,
    'faMapLocation': faMapLocation,
    'faCalendarPlus': faCalendarPlus,
    'faRefresh': faRefresh,
    'faTrashAlt': faTrashAlt,
    'faEdit': faEdit,
};

const IconColors = {
    'faBookmark': "bg-blue",
    'faThumbsUp': "bg-green",
    'faThumbsDown': "bg-red",
    'faMapLocation': "bg-map",
    'faCalendarPlus': "bg-gradient",
    'faRefresh': "bg-red",
    'faTrashAlt': "bg-red",
    'faEdit': "bg-blue",
};

module.exports = { IconActions, IconData: IconMappings, IconColor: IconColors };