import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faHome, faSignInAlt, faSignOutAlt, faUserPlus, faLock, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const isLoggedIn = localStorage.getItem('token');

  const menuItems = isLoggedIn ? (
    <>
      <div className="menu-item">
        <Link to="/home"> <FontAwesomeIcon icon={faHome} style={{ color: 'white' }} />&nbsp; Home</Link>
      </div>
      <div className="menu-item">
        <Link to="/calendartask"><FontAwesomeIcon icon={faCalendarWeek} style={{ color: 'white' }} />
          &nbsp;Calendar</Link>
      </div>
      <div className="menu-item">
        <Link to="/reset-password"><FontAwesomeIcon icon={faLock} style={{ color: 'white' }} />
          &nbsp;Change Password</Link>
      </div>
      <div className="menu-item">
        <button onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} />
          &nbsp;Logout</button>
      </div>
    </>
  ) : (
    <>
      <div className="menu-item">
        <Link to="/login"><FontAwesomeIcon icon={faSignInAlt} style={{ color: 'white' }} />
          &nbsp;Login</Link>
      </div>
      <div className="menu-item">
        <Link to="/signup"><FontAwesomeIcon icon={faUserPlus} style={{ color: 'white' }} />
          &nbsp;Sign up</Link>
      </div>
    </>
  );

  return (
    <>
      <header className="header-container">
        <div className="left-bar">
          <Link to="/home" className="logo-container">
            <img className="logo" src="/genie.gif" alt="Genie" />
          </Link>
          <h1 className="title-site">Order my Aaka</h1>
        </div>
        <div className="middle-area"></div>
        <div className="circle-container" onClick={toggleMenu} aria-expanded={isMenuOpen} aria-controls="menu-flyout">
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="hamburger-icon" />
        </div>

      </header>
      <div className='bottom-header'>

      </div>
      <div className={`menu-overlay ${isMenuOpen ? 'show' : ''}`} onClick={toggleMenu}></div>

      <div className={`menu-flyout ${isMenuOpen ? 'show' : ''}`}>
        {menuItems}
      </div>
    </>
  );
};

export default Header;
