import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import './AttributePopulation.css'; // Import CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Filter from '../modals/ModalFilter'
import DisplayDecision from '../components/DisplayDecision'
import { postError, postSuccess } from '../utils/ToastMessage';
import { Typography } from '@material-ui/core';
import LocationInput from './Location/LocationPicker';

const AttributesPopulation = ({ p }) => {
  const [data, setData] = useState(null);
  const [updated, setUpdated] = useState(false); // State to track if the object has been updated
  const [inputChanged, setInputChanged] = useState(false);
  const [refreshDecision, setRefreshDecision] = useState(false); // State to trigger refresh of DisplayDecision component
  const [userId] = useState(localStorage.getItem('userId')); // Hardcoded user ID for now
  const [userProblem, setUserProblem] = useState(null);
  const [optionalParams, setOptionalParams] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null); // Track the selected item for rendering
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item) => {
    setSelectedItem(item); // Update the selected item
  };

  // Function to update the label text
  const updateUserProblem = useCallback((obj) => {
    setUserProblem(obj);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + `/api/getAttributes/${userId}/${p}`);
        setData(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          postError('Please login again.');
        }
        else {
          postError('Error fetching data. Please try again.');
        }

        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [p, userId]);

  const handleChildSelectionChange = (values, label) => {
    setData(prevData => prevData.map(item => {
      if (item.Text === label) {
        return { ...item, Default: values };
      }
      return item;
    }));
    selectedItem.Default = values;
    setUpdated(true); // Set updated to true when the object has been updated
  };

  const handleApplyButtonClick = async () => {
    try {
      // Make a REST API call to update the user's profile data
      const putRequestURI = API_URL + `/api/updateUserProfile/${userId}/${p}`;
      await axios.put(putRequestURI, { data });
      console.log('Profile data updated successfully!');

      setRefreshDecision(!refreshDecision); // Toggle the state to trigger refresh of DisplayDecision component
      setUpdated(false); // Reset updated state after successful update
      handleCloseModal();

      postSuccess('Choices saved successfully.')
    } catch (error) {
      if (error.response.status === 401) {
        postError('Please login again.');
      }
      console.error('Error updating profile data:', error);
    }
  };

  const handleDateRangeChange = async (f) => {
    setOptionalParams((prevParams) => {
      // Create an object to hold the parameters
      const params = {};

      // Parse the existing parameters if prevParams is not empty
      if (prevParams) {
        // Split the query string into individual key-value pairs
        prevParams.split('&').forEach(param => {
          const [key, value] = param.split('=');
          // Decode the key and value and store them in the params object
          params[decodeURIComponent(key)] = decodeURIComponent(value);
        });
      }

      // Extract the key and value from the new parameter f
      const [newKey, newValue] = f.split('=');

      // Update or add the new parameter
      params[decodeURIComponent(newKey)] = decodeURIComponent(newValue);

      // Construct the new query string
      return Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    });
  };

  const handleInputSubmitClick = async (optionData) => {
    // Adding user Id to the object
    optionData.UserId = userId;

    if (!optionData.UserProblemId) {
      optionData.UserProblemId = p;
    }

    try {
      await axios.post(API_URL + `/api/saveUserDecisionAction`, optionData);
      postSuccess('Data saved successfully.')
    } catch (error) {
      if (error.response.status === 401) {
        postError('Please login again.');
      }
      else {
        postError('Error saving data. Please try again.');
      }

      console.error('Error uploading options data:', error);
    }

    setRefreshDecision(!refreshDecision); // Toggle the state to trigger refresh of DisplayDecision component
    setUpdated(false); // Reset updated state after successful update
  };

  const handleInputChange = (optionKey, inputValue) => {
    setData(prevData => prevData.map(item => {
      if (item.Text === optionKey) {
        return { ...item, Default: inputValue };
      }
      return item;
    }));
    setInputChanged(true);
  };

  const openModal = (linkIndex) => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false); // Close the modal
  };

  //const hasNatureOpen = data ? data.some(item => item.Nature === 'Input' || item.Nature === 'Addition') : false;

  return (
    <div className="grid-container-ap">
      {(
        <div>

          {userProblem && (
            <div className='title-Container'>
              <button className="repeatable-box">
                <img src={userProblem && userProblem.IconLink} alt="Icon" className="box-icon" />
                <span className="box-text">{userProblem && userProblem.Text}</span>
              </button>
              <div className='filter-Desc-text'>
                <Typography style={{ alignContent: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                  {userProblem && userProblem.Description}
                </Typography>
              </div>
              <div className="filter-icon" onClick={openModal}>
                <FontAwesomeIcon icon={faFilter} size="2x" title="Filter Results" />
              </div>
            </div>
          )}
          <Filter
            isOpen={isOpen}
            onClose={handleCloseModal}
            data={data}
            handleItemClick={handleItemClick}
            inputChanged={inputChanged}
            handleApplyButtonClick={handleApplyButtonClick}
            selectedItem={selectedItem}
            handleInputSubmitClick={handleInputSubmitClick}
            LocationInput={LocationInput}
            handleDateRangeChange={handleDateRangeChange}
            handleInputChange={handleInputChange}
            updated={updated}
            handleChildSelectionChange={handleChildSelectionChange}
          >
          </Filter>

          <div className="decision-card-Stack">
            <DisplayDecision
              p={p}
              userId={userId}
              refreshDecision={refreshDecision}
              updateUserProblem={updateUserProblem}
              optionalParams={optionalParams}
              handleDecisionSubmitClick={handleInputSubmitClick}
            />
          </div>
        </div>
      )
      }
    </div >
  );
};

export default AttributesPopulation;
