import React, { useEffect, useState } from 'react';
import '../MovieCard.css';
import PastActionGrid from '../GenericComponents/PastActionGrid';
import GenericModal from '../../modals/GenericModal';
import { Link } from 'react-router-dom';
import { faHistory, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import NewEventModal from '../../modals/NewCalendarEvent';
import { Typography, Tooltip, Button } from '@material-ui/core';
import moment from 'moment-timezone';
import { API_URL } from '../../config';
import { postError, postSuccess } from '../../utils/ToastMessage';


const { IconActions, IconData, IconColor } = require('../CardActions/ActionMapping')

const Card = ({ card, userProblem, onButtonClick, pastDecisions, onUpdateDecisionClick }) => {
    //const [isSwappingOut, setIsSwappingOut] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [filterId, setFilterId] = useState(null);
    const [image, setImage] = useState('');
    const [placeholder, setPlaceholder] = useState(null);
    const [userId] = useState(localStorage.getItem('userId'));
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const apiKey = process.env.REACT_APP_GOOGLE_PICTURE_SEARCH_API_KEY;
                const searchEngineId = process.env.REACT_APP_GOOGLE_PICTURE_SEARCH_CONTEXT;
                const query = card.title;
                const url = `https://www.googleapis.com/customsearch/v1?q=${query}&cx=${searchEngineId}&key=${apiKey}&searchType=image`;

                const response = await axios.get(url);
                const imageUrl = response.data.items[0].link;

                setImage(imageUrl);
                card.image = imageUrl; // Assigning the fetched image URL to card's image property
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [card]);

    const handleButtonClick = (item, card) => {
        //setIsSwappingOut(true);
        onButtonClick(item.id, item.text);

        if (item.action) {
            // Get the action class instance from the mapping
            const action = IconActions[item.action]
            if (action) {
                // Perform the action for the action class
                let popup = action.performAction(card.title);

                if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                    postError('Please enable popup for this site, to see content.');
                }
            }
        }
    };

    const openModal = (linkIndex) => {
        setFilterId(linkIndex);
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false); // Close the modal
    };

    const handleSelectSlot = (start, title, notes) => {
        setPlaceholder(<NewEventModal isOpen={true} onClose={handleCloseFinModal} onSubmitClick={saveEvent} existingCard={{ "start": start, "end": moment(start).add(30, 'minutes'), "title": title, "notes": notes, type: 'Food' }} />);
    };

    const handleCloseFinModal = () => {
        //setIsFinModalOpen(false); // Close the modal
        setPlaceholder(<></>);
    };

    const saveEvent = async (newEvents) => {
        try {
            const postBody = {
                UserId: userId,
                type: 'UserEvent',
                results: []
            };

            newEvents.start = moment(newEvents.start).utc().format();
            newEvents.end = moment(newEvents.end).utc().format();

            postBody.results.push(...newEvents);

            await axios.post(API_URL + `/api/saveUserCalendarTasks`, postBody);
            setEvents([...events, ...newEvents]);
            postSuccess('Event saved successfully.')
        } catch (error) {
            if (error.response.status === 401) {
                postError('Please login again.');
            }
            else {
                postError('Error saving calendar event. Please try again.');
            }

            console.error('Error saving calendar event:', error);
        }
    };

    return (
        <div className="control" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("${image}")` }}>
            <div className="content-column">
                <div className="card-content">
                    <h2 className="title">{card.title}</h2>
                    <pre className="overview">{card.description} *{card.calories}.</pre>
                    <pre className="overview">{card.ingredients ?
                        <div>
                            <div className='food-titlerow-cal-icon'>
                                <Typography variant="h5" gutterBottom fontWeight="bold">Ingredients:</Typography>
                                <Tooltip title="Add a Pickup Reminder" aria-label="Add a pickup reminder">
                                    <Button
                                        className="gradient-button"
                                        startIcon={<FontAwesomeIcon icon={faCalendarPlus} />}
                                        onClick={() => handleSelectSlot(moment(), `Pick Ingredients for - ${card.title}`, card.ingredients)}
                                    >
                                        Add to Calendar
                                    </Button>
                                </Tooltip>
                            </div>
                            {card.ingredients}
                        </div>
                        :
                        ""
                    }
                    </pre>
                    <pre className="overview">{card.directions ?
                        "Directions: \n" + card.directions
                        :
                        ""
                    }
                    </pre>
                </div>

                <div className="overview">{card.overview}</div>
                <div className="icons">
                    {userProblem && userProblem.Buttons && userProblem.Buttons.length > 0 && (
                        userProblem.Buttons.map((item, index) => (
                            <div>
                                <Tooltip title={item.Description} aria-label={item.Description}>
                                    <span className={`icon-circle ${IconColor[item.text]}`} onClick={() => handleButtonClick(item, card)}>
                                        <FontAwesomeIcon key={item.id} icon={IconData[item.text]} style={{ color: 'white' }} size="2x" aria-label={item.text}></FontAwesomeIcon>
                                    </span>
                                </Tooltip>
                                <div className="view-link-container">
                                    <Tooltip title="Past Items" aria-label="Past Items">
                                        <Link to="#" onClick={() => openModal(item.id)}><FontAwesomeIcon icon={faHistory} size="lg" style={{ color: 'blue' }} aria-label="History" /></Link>
                                    </Tooltip>
                                </div>
                            </div>
                        )))}
                </div>
            </div>
            <GenericModal
                isOpen={isOpen}
                Component={PastActionGrid}
                componentProps={{
                    data: pastDecisions,
                    filterId: filterId,
                    buttons: userProblem.Buttons,
                    onUpdateDecisionClick: onUpdateDecisionClick,
                    onClose: handleCloseModal
                }}
            />
            {placeholder}
        </div>
    );
};

export default Card;