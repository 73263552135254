import React, { useState, useEffect } from 'react';

const MultiSelectChoice = ({ label, options, preSelectedOptions, onSelectionChange }) => {

    // Inside your component function
    const [selectedOptions, setSelectedOptions] = useState(preSelectedOptions);

    useEffect(() => {
        setSelectedOptions(preSelectedOptions || []);
    }, [preSelectedOptions]);

    const handleOptionClick = (option) => {
        let updatedSelection;

        if (typeof option === 'string') {
            if (selectedOptions.includes(option)) {
                updatedSelection = selectedOptions.filter((item) => item !== option);
            } else {
                updatedSelection = [...selectedOptions, option];
            }
        }
        else {
            if (selectedOptions.find(selectedOption => selectedOption.id === option.id) !== undefined) {
                updatedSelection = selectedOptions.filter((item) => item.id !== option.id);
            } else {
                updatedSelection = [...selectedOptions, option];
            }
        }

        setSelectedOptions(updatedSelection);
        preSelectedOptions = updatedSelection;

        // Notify parent component of the change in selection
        if (onSelectionChange) {
            onSelectionChange(updatedSelection, label);
        }
    };

    return (
        <>
            <React.Fragment>
                {true && (
                    <div className="">
                        <div className="">
                            <div className='selected-options-container-msd'>
                                {selectedOptions.map((option, index) => (
                                    <div className='selected-options-container-msd'>
                                        {typeof option === 'string' ? (// If option is an array, render it as is
                                            <React.Fragment>
                                                <div key={index} className="selected-option-msd">
                                                    {index !== 0} {option}
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div key={index} className="selected-option-msd">
                                                    {index !== 0} {option.name}
                                                </div>
                                            </React.Fragment>
                                        )
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="options-list-msd">
                                {options.map((option, index) => (
                                    <div key={index} className="option-msd" onClick={() => handleOptionClick(option)}>
                                        {typeof option === 'string' ? (// If option is an array, render it as is
                                            <React.Fragment>
                                                <input type="checkbox" checked={selectedOptions.includes(option)} readOnly />
                                                <span>
                                                    {option}
                                                </span>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <input type="checkbox" checked={selectedOptions.some(item => item.id === option.id)} readOnly />
                                                <span>
                                                    {option.name} {option.english_name ? "(" + option.english_name + ")" : ''}
                                                </span>
                                            </React.Fragment>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
                }

            </React.Fragment>

        </>
    );
};
export default MultiSelectChoice