import React, { useState, useEffect } from 'react';
import '../../modals/AdditionModal.css';
import './AddFinEntry.css'
import { GUID_ManageFin } from '../../config';
import moment from 'moment';
import {
    Select,
    MenuItem,
    TextField,
    Button,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Paper,
} from '@material-ui/core';

const AddFinEntry = ({ onClose, callingOption, onSubmitClick, attributeId, parent, optionsFromParent, existingCard }) => {
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [step, setStep] = useState(1); // Step 1: Category Selection, Step 2: Form
    const [formData, setFormData] = useState({
        id: '',
        title: '',
        amount: '',
        notes: '',
        frequency: '',
        start: moment(),
        end: moment().add(1, 'hours'),
        customFrequency: '',
        occurances: 0,
        customProps: [],
        isActive: true,
        subCat: '',
        currency: '',
        CallingOption: parent.Text,
    });

    useEffect(() => {
        if (optionsFromParent.length === 1) {
            setStep(2);
            setFormData(prevFormData => ({
                ...prevFormData,
                subCat: optionsFromParent[0].name
            }));
        }

        if (callingOption === 'View') {
            setIsReadOnly(true);
            setFormData(existingCard);
            setStep(2);
        } else if (callingOption === 'Edit') {
            setIsEdit(true);
            setFormData(existingCard);
            setStep(2);
        } else if (callingOption === 'Delete') {
            setIsDelete(true);
            setIsReadOnly(true);
            setFormData({ ...existingCard, isActive: false });
            setStep(2);
        }
    }, [callingOption, existingCard, optionsFromParent]);

    const handleCategorySelect = (category) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            subCat: category,
        }));
        setStep(2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isDelete) {
            formData.isActive = false;
        }

        let newEvents = generateRecurringEvents(
            formData.title,
            formData.start,
            formData.start,
            formData.frequency,
            formData.customFrequency,
            formData.occurances,
            formData.amount,
            formData.notes,
            formData.currency,
            formData.subCat
        );

        if (isDelete || isEdit) {
            newEvents = newEvents.map(event => ({
                ...event,         // Keep existing properties
                CallingOption: existingCard.CallingOption,
                type: existingCard.type,
                id: existingCard.id
            }));
        }

        const postBody = {
            type: 'UserDecisionMap',
            AttributeId: attributeId,
            UserProblemId: GUID_ManageFin,
            results: newEvents,
        };

        if (onSubmitClick) {
            await onSubmitClick(postBody);
        }

        onClose();
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const generateRecurringEvents = (name, start, end, frequency, customFreq, occurances, amount, notes, currency, subCat) => {
        let freq, amt = amount;
        let multiplyFactor = 1;
        const recurrenceEvents = [];

        switch (frequency) {
            case 'daily':
                freq = 'days';
                break;
            case 'weekly':
                freq = 'weeks';
                break;
            case 'monthly':
                freq = 'months';
                break;
            case 'yearly':
                freq = 'years';
                break;
            case 'custom':
                freq = 'days';
                multiplyFactor = customFreq;
                break;
            default:
                amt = amount;
                break;
        }

        recurrenceEvents.push({
            title: name,
            start: moment(start),
            end: moment(end),
            amount: amt,
            frequency: frequency,
            occurances: occurances,
            customFrequency: customFreq,
            isActive: formData.isActive,
            notes: notes,
            CallingOption: parent.Text,
            currency: currency,
            subCat: subCat,
            type: 'Finance',
        });

        if (frequency !== '' && !isDelete) {
            for (let count = 1; count < occurances; count++) {
                const currmo = moment(start).add(multiplyFactor * count, freq);
                const endmo = moment(end).add(multiplyFactor * count, freq);

                recurrenceEvents.push({
                    title: name,
                    start: currmo,
                    end: endmo,
                    amount: amt,
                    frequency: frequency,
                    occurances: occurances,
                    customFrequency: customFreq,
                    isActive: true,
                    notes: notes,
                    CallingOption: parent.Text,
                    currency: currency,
                    subCat: subCat,
                    type: 'Finance',
                });
            }
        }

        return recurrenceEvents;
    };

    return (
        <div>
            {(step === 1) && (
                <div>
                    <Typography variant="h5" gutterBottom>Select a Category</Typography>
                    <Grid container spacing={3}>
                        {optionsFromParent.map((category, index) => (
                            <Grid item xs={4} key={index}>
                                <Paper
                                    onClick={() => handleCategorySelect(category.name)}
                                    className='finEntry-step'
                                >
                                    <Typography variant="h6">{category.name}</Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )}

            {(step === 2) && (
                <div>
                    <Typography variant="h5" gutterBottom>{parent.Description}</Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        value={formData.subCat}
                                        label="Category"
                                        name="subCat"
                                        MenuProps={{
                                            style: { zIndex: 35001 },
                                        }}
                                        readOnly={isReadOnly}
                                        onChange={handleChange}
                                    >
                                        {!isReadOnly ? optionsFromParent.map((option, index) => (
                                            <MenuItem key={index} value={option.name}>
                                                {option.name}
                                            </MenuItem>
                                        ))
                                            :
                                            <MenuItem value={existingCard.subCat}>
                                                {existingCard.subCat}
                                            </MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    value={formData.title}
                                    InputProps={{
                                        readOnly: isReadOnly,
                                    }}
                                    onChange={(event) => {
                                        setFormData(prevFormData => ({
                                            ...prevFormData,
                                            title: event.target.value,
                                        }));
                                    }}
                                    fullWidth
                                    required
                                    openOnFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth>
                                    <InputLabel>Currency</InputLabel>
                                    <Select
                                        value={formData.currency}
                                        label='Currency'
                                        name="currency"
                                        MenuProps={{
                                            style: { zIndex: 35001 }
                                        }}
                                        readOnly={isReadOnly}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="INR">₹</MenuItem >
                                        <MenuItem value="USD">$</MenuItem >
                                        <MenuItem value="EUR">€</MenuItem >
                                        <MenuItem value="GBP">£</MenuItem >
                                        <MenuItem value="Yen">¥</MenuItem >
                                        <MenuItem value="OTR">Other</MenuItem >
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    fullWidth
                                    label="Amount"
                                    name="amount"
                                    variant="outlined"
                                    value={formData.amount}
                                    type="number"
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    type="date"
                                    name="start"
                                    label="Date"
                                    value={moment(formData.start).format('YYYY-MM-DD')}
                                    onChange={(date) => setFormData({
                                        ...formData, start: moment(formData.start).set({
                                            year: moment(date.target.value).format('YYYY'),
                                            month: moment(date.target.value).add(-1, 'months').format('MM'),
                                            date: moment(date.target.value).format('DD')
                                        }).format('YYYY-MM-DDTHH:mm:ss')
                                    })
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: isReadOnly
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel>Repeat</InputLabel>
                                    <Select
                                        value={formData.frequency}
                                        label='Frequency'
                                        name="frequency"
                                        MenuProps={{
                                            style: { zIndex: 35001 }
                                        }}
                                        readOnly={isReadOnly}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="monthly">Monthly</MenuItem >
                                        <MenuItem value="daily">Daily</MenuItem >
                                        <MenuItem value="weekly">Weekly</MenuItem >
                                        <MenuItem value="yearly">Yearly</MenuItem >
                                        <MenuItem value="custom">Custom</MenuItem >
                                    </Select>
                                </FormControl>
                            </Grid>
                            {formData.frequency !== '' && (
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="How many times"
                                        type="number"
                                        name="occurances"
                                        variant="outlined"
                                        value={formData.occurances}
                                        onChange={handleChange}
                                        inputProps={{ min: 2 }}
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                    />
                                </Grid>
                            )}
                            {formData.frequency === 'custom' && (
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Repeat after (days)"
                                        type="number"
                                        name="customFrequency"
                                        variant="outlined"
                                        value={formData.customFrequency}
                                        onChange={handleChange}
                                        inputProps={{ min: 2 }}
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                    />
                                </Grid>
                            )}
                            {formData.frequency !== '' ?
                                <Typography>
                                    * Repeat this event,
                                    {
                                        formData.frequency !== 'custom' ?
                                            " " + formData.frequency + ", " :
                                            (
                                                formData.customFrequency === '' ?
                                                    " please select after how many days to repeat the event, " :
                                                    " every " +
                                                    formData.customFrequency +
                                                    " days, "
                                            )
                                    }
                                    for the next
                                    {
                                        formData.occurances !== undefined ?
                                            " " + formData.occurances +
                                            " times."
                                            :
                                            " please select how many time"
                                    }
                                </Typography> :
                                <></>
                            }
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    color={isDelete ? "secondary" : "primary"}
                                >
                                    {isDelete ? "Delete" : isEdit ? "Update" : "Add"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            )}
        </div>
    );
};

export default AddFinEntry;
