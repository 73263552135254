import React, { useState } from 'react';
import MonthYearPicker from '../components/CalendarTasks/CustomDatePicker';
import MSC from '../components/GenericComponents/MultiSelectChoice'
import RepeatedButtonsWithInput from '../components/RepeatedButtonsWithInput'
import './ModalFilter.css'

const ModalFilter = ({ isOpen, onClose, data, handleItemClick, inputChanged, handleApplyButtonClick, selectedItem, handleInputSubmitClick, LocationInput, handleDateRangeChange, handleInputChange, updated, handleChildSelectionChange }) => {
    const [selectedItem2, setSelectedItem2] = useState(null); // State to track selected item

    const handleClick = (item) => {
        setSelectedItem2(item); // Update selected item
    };

    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={onClose}>X</button>
                        <div className="apply-button-container">
                            <button onClick={handleApplyButtonClick} disabled={!updated} className="apply-button">
                                {updated ? "Apply" : "Please select something to enable"}
                            </button>
                        </div>
                        <div className="two-column-container">
                            <div className="left-column">
                                <ul className={`atr-list2`}>
                                    {data.map((item, index) => (
                                        <li key={index} onClick={() => handleItemClick(item)}>
                                            <div className={`filter-options ${selectedItem2 === item ? 'selected' : ''}`} onClick={() => handleClick(item)}>
                                                {item.Text}
                                            </div>
                                        </li>
                                    ))}
                                    <li className="apply-button-container">
                                        {inputChanged && (
                                            <button onClick={handleApplyButtonClick} disabled={!inputChanged} className="apply-button">
                                                Apply
                                            </button>
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className="right-column">
                                {selectedItem && (
                                    <>
                                        {selectedItem.Nature === "Addition" ? (
                                            <RepeatedButtonsWithInput
                                                id={`dropdown-${selectedItem.id}`}
                                                options={selectedItem.Options}
                                                onSubmitClick={handleInputSubmitClick}
                                                attributeId={selectedItem.id}
                                                parent={selectedItem}
                                            />
                                        ) : selectedItem.Nature === "MonthYearPicker" ? (
                                            <MonthYearPicker onSelectionChange={(e, f) => handleDateRangeChange("DateRange=" + e, f)} />
                                        ) : selectedItem.Nature === "Input" ? (
                                            <div className="atr-loc-picker">
                                                <LocationInput onLocationSelect={(e) => handleInputChange(selectedItem.Text, e)} />
                                            </div>
                                        ) : (
                                            <MSC
                                                options={selectedItem.Options}
                                                preSelectedOptions={selectedItem.Default}
                                                handleApplyButtonClick={handleApplyButtonClick}
                                                updated={updated}
                                                label={selectedItem.Text}
                                                onSelectionChange={handleChildSelectionChange}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                    </div>
                </div >
            )}
        </>
    );
};

export default ModalFilter