import React from 'react';
import ActionGrid from '../GenericComponents/ActionGrid';

const ManageSubCard = ({ userProblem, handleDecisionSubmitClick }) => {
    const columnKeys = [
        {
            value_from: 'subCat',
            display_name: 'Category',
            type: 'text'
        },
        {
            value_from: 'title',
            display_name: 'Title',
            type: 'text'
        },
        {
            value_from: 'amount',
            display_name: 'Amount',
            type: 'currency', // Specify type as currency
            formatter: {
                value_from: 'currency',
            }
        },
        {
            value_from: 'start',
            display_name: 'Date',
            type: 'date', // Specify type as date
            formatter: {
                format: 'DD/MM/YYYY' // Example format, adjust as needed
            }
        },
        {
            value_from: "actions",
            display_name: "Actions",
            type: "buttons",
            render: [
                { id: 1, text: "faEdit", Description: "Edit" },
                { id: 2, text: "faTrashAlt", Description: "Delete" }
            ]
        }
    ];

    return (
        <ActionGrid
            data={userProblem}
            columns={columnKeys}
            handleDecisionSubmitClick={handleDecisionSubmitClick}
        >
        </ActionGrid>
    );
};

export default ManageSubCard;
