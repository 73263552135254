// frontend/src/pages/Home.js

import React from 'react';
import Header from '../components/Header';
import RepeatableBoxList from '../components/RepeatableBoxList';
import './Home.css'; // Import CSS file

const Home = () => {
  return (
    <div className="homepage-container">
      <Header />
      <RepeatableBoxList />
    </div>
  );
};

export default Home;
